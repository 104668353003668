import '../App.css';
import { Icon } from '@iconify/react';
import Socials from './Socials';

export default function Footer() {
    return (
        <div className='footer'>

            <Socials />

        </div >
    )
}